<template>
  <div class="reset">
    <el-card>
      <div class="text-center">
        <h1>重置密码</h1>
      </div>
      <el-form
        label-position="left"
        label-width="120px"
        :model="form"
        ref="form"
        :rules="rules"
        hide-required-asterisk
      >
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="短信验证码：" prop="codes">
          <!-- <el-input v-model="form.mobile" placeholder="请输入短信验证码"></el-input> -->
          <el-input placeholder="请输入短信验证码" v-model="form.codes">
            <template slot="append">
              <el-button @click="getCode" :disabled="show">{{
                sendCon
              }}</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="password">
          <el-input
            v-model="form.password"
            type="password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="rpassword">
          <el-input
            v-model="form.rpassword"
            type="password"
            placeholder="请确认密码"
          ></el-input>
        </el-form-item>
        <el-form-item label>
          <el-button type="danger" class="login" @click="reset"
            >重置密码</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Login',
  data() {
    var validateMobile = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        if (/^1[3456789]\d{9}$/.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号'))
        }
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.form.rpassword !== '') {
          this.$refs.form.validateField('rpassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      form: {
        mobile: '',
        codes: '',
        password: '',
        rpassword: '',
      },
      rules: {
        mobile: {
          required: true,
          validator: validateMobile,
          message: '请输入手机号',
          trigger: ['blur', 'change'],
        },
        password: { required: true, validator: validatePass, trigger: 'blur' },
        codes: { required: true, message: '请输入验证码', trigger: 'blur' },
        rpassword: {
          required: true,
          validator: validatePass2,
          trigger: 'blur',
        },
      },

      show: false,
      count: '',
      timer: null,
      sendCon: '获取验证码',
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {},
    link(name) {
      this.$router.push({ name: name })
    },
    reset() {
      let _this = this
      this.$refs.form.validate(valid => {
        if (valid) {
          _this.$http
            .post(process.env.VUE_APP_URL + 'resetPassword', this.form)
            .then(data => {
              if (data.data.code == 200) {
                _this.$message.success('修改成功')
                this.$router.push({ name: 'Login' })
              } else {
                _this.$message.error(data.data.msg)
              }
            })
            .catch(err => {
              _this.$message.error('网络错误，请重试')
              console.log(err)
            })
        } else {
          return false
        }
      })
    },
    getCode() {
      let _this = this
      let params = {
        type: 'reset',
        mobile: this.form.mobile,
      }
      if (/^1[3456789]\d{9}$/.test(params.mobile)) {
        _this.$http
          .post(process.env.VUE_APP_URL + 'sendCode', params)
          .then(data => {
            if (data.data.code == 200) {
              _this.countdown()
              _this.$message.success('验证码已发送')
            } else {
              _this.$message.error(data.data.msg)
            }
          })
          .catch(err => {
            _this.$message.error('网络错误，请重试')

            console.log(err)
          })
      } else {
        this.$message.warning('请输入正确格式的手机号')
        return
      }
    },
    countdown() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT

        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.show = true
            this.count--
            this.sendCon = this.count + '秒后再次获取'
          } else {
            this.show = false
            clearInterval(this.timer)
            this.timer = null
            this.sendCon = '获取验证码'
          }
        }, 1000)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.reset {
  font-weight: normal;
  font-size: 16px;
  color: #333;
  a:hover {
    text-decoration: none;
    color: orange;
    cursor: pointer;
  }
  .text-center {
    color: #434346;
    margin-bottom: 50px;
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 18px;
    }
  }
  .el-card {
    width: 620px;
    margin: 60px auto;
    /deep/.el-card__body {
      padding: 40px;
    }
    .el-form {
      /deep/.el-form-item__label {
        font-size: 18px;
      }
    }
    .login {
      background: #e10133;
      width: 300px;
      color: #fff;
      margin-top: 20px;
      margin-left: 20px;
    }
  }
}
</style>
